class LabelItemCtrl {
  /**
   * @ngInject
   * @param {JQLite} $element
   */
  constructor($element) {
    this.Element = $element
  }
}

export { LabelItemCtrl }
