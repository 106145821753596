class FormViewCtrl {
  /**
   * @ngInject
   * @param {ng.IScù} $scope
   */
  constructor($scope) {
    this.Scope = $scope
  }

  init() {}
}

export { FormViewCtrl }
